import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import innovationImage from "../images/howcan.jpg"
import stakeholderImage from "../images/stakeholder.jpg"
import pbarnesImage from "../images/pbarnes.jpg"
import fishImage from "../images/fish.jpg"

const resources = () => (
  <Layout>
    <SEO title="Resources" />
    <div id="whyInspire" class="px-4 mt-2 mb-5 text-center">
      <h1 class="display-5 fw-bold">Why Inspire?</h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4 text-start">
          Inspire was created to assist Global Mobility and Procurement
          stakeholders to reduce the carbon footprint of their program, deliver
          value from existing providers or through an optimised RFP process. Our
          consultancy covers the key areas of ESG/Sustainability, the RFP Tender
          Process, Innovative Solutions and Technology to ensure educated
          decisions are taken and the desired results achieved.
        </p>

        <p class="lead mb-4 text-start">
          Working solely for Global Mobility stakeholders and not Relocation
          Providers on RFP tenders means Inspire is truly transparent in the
          advice provided to ensure you obtain transparent advice and achieve
          your RFP goals.
        </p>
        <p class="lead mb-4 text-start">
          Utilising our expertise, knowledge, and unique industry insight we
          assist companies overcome common challenges including the following.
        </p>
        <ul class="lead text-start">
          <li>
            Achieving an effective ESG program and reducing their carbon
            footprint.
          </li>
          <li>
            Lack of knowledge or time to audit providers to achieve cost savings
            and additional value.
          </li>
          <li>
            Unsure what Innovation, Sustainability or Technology direction to
            take.
          </li>
          <li>
            RFPs do not run to time, increases stress levels for all and
            decreases the value that is achieved.
          </li>
          <li>
            High level of RFP questions from respondents that can derail the
            process as it begins.
          </li>
          <li>
            RFP pricing complex so a direct like for like comparison is hard or
            unable to be achieved.
          </li>
        </ul>
        <p class="lead text-start">
          Due to these issues and numerous others, added value opportunities and
          cost savings in your program can be missed. For example, driving
          sustainability and reducing your carbon footprint can become complex,
          RFP’s can quickly disengage potential providers. This disengagement if
          unchecked leads to difficult contract negotiations, implementation and
          go live with the new provider which is not the way to start a long
          term and productive provider relationship that will deliver the value
          you need. We ensure clients achieve a highly engaged, time and
          cost-efficient process that delivers their goals and objectives.
        </p>
        <p class="lead text-start">
          During a period of disengagement potential cost savings and value add
          opportunities can be missed. We ensure clients achieve a highly
          engaged, time and cost-efficient RFP tender process that delivers
          their goals and objectives.
        </p>
        <p class="lead text-start">
          Inspire assists clients by sharing unique industry intelligence and
          knowledge to ensure educated decisions are made through to delivering
          solutions to complex challenges.
        </p>
        <p class="lead">
          <strong>
            Let Inspire unlock cost savings and maximize value opportunities for
            you!
          </strong>
        </p>
      </div>
    </div>

    <div class="rotate" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div id="sustainability" class="container-flex bg-coolblue">
      <div class="container col-xxl-8 px-4">
        <div class="row flex-lg-row align-items-center g-5 bg-coolblue">
          <div class="col-lg-6">
            <img
              src={fishImage}
              class="d-block mx-lg-auto img-fluid rounded"
              alt="Bootstrap Themes"
              width="612"
              height="414"
              loading="lazy"
            />
          </div>
          <div class="col-lg-6 text-light">
            <h1 class="display-5 fw-bold lh-1 mb-3">
              Sustainability in Global Mobility!
            </h1>
            <p class="lead">
              Inspire works with Global Mobility Professionals who need to
              change their thinking, practices, and policies to deliver a
              positive sustainable future.
            </p>
            <p class="lead text-start">
              Paul started the fast-growing Sustainability in Global Mobility
              group to ask questions, share knowledge and best practice of the
              actions Global Mobility Professionals and Service Providers to
              positively educate and influence, whilst encouraging
              collaboration.
            </p>
            <p class="lead text-start">
              <b>Global Mobility &amp; Procurement:</b> There are simple and
              very effective solutions that can be adapted based upon your
              companies’ culture and demographic of relocating employee which
              deliver the following results.
            </p>
            <ol type="1" class="lead text-start">
              <li class="mb-2">Reduce your programs carbon footprint.</li>
              <li class="mb-2">Increased employee engagement.</li>
              <li class="mb-2">Reduce cost.</li>
            </ol>
          </div>
          <div class="px-4 text-center bg-coolblue text-light m-0">
            <div class="col-lg-12 mx-auto">
              <p class="lead mb-4 text-start">
                Planning an RFP and need to ensure you work with sustainable
                providers? There are no industry standards but there are
                effective ways to ensure you evaluate and source a sustainable
                provider.
              </p>
              <p class="lead text-start">
                <b>Mobility Service Providers:</b> The Inspire approach is based
                on your individual needs. Understanding of your business model
                to help you evolve to a sustainable business.
              </p>
              <p class="lead text-start">
                <b>Objective:</b> Build a program based upon availability of
                resources that has meaning, is easily explained, backed by data
                and integral to your company.
              </p>
              <ol type="1" class="lead text-start">
                <li class="mb-2">
                  Develop a roadmap &amp; timeline of actions and commitments.
                </li>
                <li class="mb-2">
                  Save time, cut through endless evaluation and terminology.
                </li>
                <li class="mb-2">
                  Help you justify your actions; no company is perfect!
                </li>
              </ol>
              <p class="lead text-start">
                <b>
                  Contact Inspire if you would like to discuss sustainability
                  solutions and how ‘morality’ will help you positively
                  influence and educate!
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rotate-180" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div id="howInspireCanHelpYou" class="container-flex">
      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5">
          <div class="col-lg-6">
            <img
              src={innovationImage}
              class="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div class="col-lg-6 ">
            <h1 class="display-5 fw-bold lh-1 mb-3">
              How Inspire Can Help You!
            </h1>
            <p class="lead">
              Inspire works with Global Mobility stakeholders to deliver value
              and maximise cost savings across your program in the following
              areas:
            </p>
            <ol type="1" class="lead text-start">
              <li class="mb-2">
                <a
                  target="_blank"
                  class="link-text text-dark text-underline"
                  href="https://www.linkedin.com/groups/9043947/"
                >
                  <u>ESG</u>
                </a>{" "}
                - Sustainability viability studies to optimize options that
                reduce your carbon footprint and enhance global sustainability.
              </li>
              <li class="mb-2">
                The RFI - RFP tender process from pre planning and delivery
                phase through to contract negotiations, implementation and go
                live with the chosen provider. Inspire ensures clients achieve a
                time and cost-efficient tender process that achieves their goals
                and objectives.
              </li>
              <li class="mb-2">
                Reviewing existing providers to identify cost savings and value
                opportunities. Undecided if you should go to tender, or you are
                extending an existing contract then these services are ideally
                suited to you.
              </li>
              <li>
                Technology and Innovation workshops to discover how they will
                add value to your program.
              </li>
            </ol>
          </div>
          <div class="px-4 text-center m-0">
            <div class="col-lg-12 mx-auto">
              <p class="lead mb-4 text-start">
                Our approach is completely flexible working with clients on the
                areas of their mobility program or RFP where they need our
                assistance. The following are additional examples of how Inspire
                adds value.
              </p>
            </div>
            <div class="row bg-coolorange text-light rounded-3 coolshadow pt-4 pb-4 mb-4">
              <div class="col-lg-6">
                <p class="lead text-centre">
                  <strong>RFP Focused:</strong>
                </p>
                <ul class="lead text-start">
                  <li class="mb-2">
                    Refining your goals and objectives whilst ensuring they are
                    realistic!
                  </li>
                  <li class="mb-2">
                    Stakeholder Alignment Workshops to unify stakeholder goals
                    and objectives.
                  </li>
                  <li class="mb-2">When to use an RFI and its value.</li>
                  <li class="mb-2">
                    Produce RFP documents focused on client&#39;s unique goals
                    &amp; objectives to maximise the quality of responses and
                    pricing.
                  </li>
                  <li>
                    Pricing format optimized to meet clients' requirements and
                    achieve the best pricing.
                  </li>
                </ul>
              </div>
              <div class="col-lg-6">
                <p class="lead text-centre">
                  <strong>Program Focused:</strong>
                </p>
                <ul class="lead text-start">
                  <li class="mb-2">
                    Enhancing Sustainability in your mobility program.
                  </li>
                  <li class="mb-2">
                    Compliance Audit of billing against the contract conditions
                    and pricing.
                  </li>
                  <li class="mb-2">
                    Options to increase value from existing providers and not go
                    to RFP.
                  </li>
                  <li class="mb-2">
                    Evaluate and simplify areas of complex administration.
                  </li>
                  <li class="mb-2">
                    Optimize service specifications and pricing for ease of use.
                  </li>
                  <li class="mb-2">
                    Evaluate if you truly have a quality provider.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12 mx-auto">
              <p class="lead mb-4">
                Inspire assists clients by sharing unique industry intelligence
                and knowledge to ensure educated decisions are made through to
                assisting in delivering solutions to complex challenges.
              </p>
              <p class="lead">
                <strong>
                  Let Inspire unlock cost savings and maximise value
                  opportunities for you!
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rotate" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div
      id="expertiseAndKnowledge"
      class="px-4 text-center bg-coolblue text-light"
    >
      <h1 class="display-5 fw-bold">Expertise And Knowledge?</h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4 text-start">
          Paul Barnes who leads the Inspire consultancy has over 28 years’
          experience in the amazing world of Global Mobility, with a proven
          track record of success in senior management, account management,
          consultancy, and business development. 
        </p>

        <p class="lead mb-4 text-start">
          Paul has a keen interest in discovering new and innovative approaches
          to mobility, along with the drive for value that technology can
          achieve. Sustainability is another key interest and one where Paul
          promotes the achievements of all Global Mobility stakeholders to
          educate and reduce the Global Mobility carbon footprint.
        </p>

        <div class="row flex-lg align-items-center g-5">
          <div class="col-sm-6">
            <img
              src={pbarnesImage}
              class="rounded-pill d-block mx-lg-auto img-fluid w-75"
              alt="..."
            />
          </div>

          <div class="col-sm-6">
            <p class="lead mb-4 text-start">
              His extensive experience comes from working with Multinational
              companies in a diverse range of industries, nationalities, and
              cultures. This provides Paul with valuable knowledge, cultural
              awareness, and life skills which he uses to consult with Global
              Mobility and Procurement stakeholders to understand their
              objectives and deliver long term strategic value.
            </p>
            <p class="lead mb-4 text-start">
              From mature to start up or fast-growing entrepreneurial companies
              Paul has unique industry insight and a vast range of experience to
              share. For further information or to connect please check out{" "}
              <a
                target="_blank"
                class="link-text text-light text-underline"
                href="https://www.linkedin.com/in/paulbarnes1/"
              >
                <strong>
                  <u>Pauls LinkedIn</u>
                </strong>
              </a>
            </p>
          </div>
        </div>

        <p class="lead mb-0 mt-5">
          <strong>
            Use our knowledge and expertise to achieve the results you need!
          </strong>
        </p>
      </div>
    </div>

    <div class="rotate-180" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div id="howIsYourProgramPerforming" class="container-flex ">
      <div class="container col-xxl-8 px-4 py-5 ">
        <div class="row flex-lg align-items-center g-5">
          <div class="col-lg-6">
            <img
              src={stakeholderImage}
              class="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div class="col-lg-6 ">
            <h1 class="display-5 fw-bold lh-1 mb-3">
              How Is Your Provider Performing?
            </h1>
            <p class="lead">
              This range of services is designed to overcome challenges and
              review existing providers to identify cost and administration
              savings whilst identifying additional value in your program. When
              was the last time you audited the billing of your provider?
              Undecided if you should go to tender, or you are extending an
              existing contract then these services are ideally suited to you.
            </p>
            <ul class="lead text-start">
              <li class="mb-2">
                Compliance Audit of billing against the contract conditions and
                pricing.
              </li>
              <li class="mb-2">
                Change management through to optimizing complex situations.
              </li>
              <li class="mb-2">
                Evaluate and simplify complex areas of administration.
              </li>
              <li class="mb-2">
                Optimize service specifications and pricing for ease of use.
              </li>
              <li>
                Evaluate if you truly have a quality provider and if they value
                your business.
              </li>
            </ul>
          </div>
          <div class="px-4 text-center  m-0">
            <div class="col-lg-12 mx-auto">
              <p class="lead mb-4 text-start">
                Inspire provides independent advice and unique market
                intelligence to identify areas where additional value and cost
                savings can be achieved.
              </p>
              <p class="lead">
                <strong>
                  Let Inspire unlock cost savings and maximize value
                  opportunities in your program.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rotate" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <div id="needAnalysis" class="px-4 text-center bg-coolblue text-light">
      <h1 class="display-5 fw-bold">Need Analysis It all starts here!</h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4 text-start">
          Inspire is here to help you through your Innovation, Provider,
          Sustainability, and Technology reviews or at any stage of your RFP
          tender. We increase the speed at which educated decisions are made and
          assist clients by utilizing our skills and knowledge to deliver their
          desired results.
        </p>

        <p class="lead mb-4 text-start">
          Our engagement typically starts with a needs analysis to fully
          understand our clients existing situation, through to discussing their
          goals and objectives. We share alternate solutions and how they can
          deliver value, whilst being open about how realistic and achievable
          our clients’ goals and objectives are. This approach defines the
          client&#39;s goals and objectives so they can picture what success
          will look like! This is an essential element of planning a successful
          course of action to achieve the desired results.
        </p>

        <p class="lead mb-4 text-start">
          More specifically to an RFP tender process the following are examples
          of the areas covered. We explore the clients' internal stakeholders
          involved, timelines required and consult on what is achievable taking
          key elements into consideration. We share information on the market
          from technology, and sustainability through to provider tiers and
          which tiers will potentially offer the best value to the client and
          why.
        </p>

        <p class="lead mb-4 text-start">
          Some clients require one contracted provider for all services, whilst
          others may be interested in an{" "}
          <Link to="/blog5" className="link-text text-light underline">
            <strong>
              <u>Ecosystem model</u>
            </strong>
          </Link>
          <span>, </span>where they contract with specialised providers to
          deliver the quality and services required. The ecosystem model can be
          highly effective, but we share what makes this model succeed or fail.
          Pricing is discussed with the potential for maximising cost savings
          with a clear and effective pricing format.
        </p>

        <p class="lead mb-4 text-start">
          Following the needs analysis, a detailed report is compiled
          highlighting key value areas based upon the clients' unique needs.
        </p>

        <p class="lead mb-4 text-start">
          The report includes the challenges, opportunities, and course of
          action to deliver the desired results. To achieve full value from the
          need&#39;s analysis report we suggest a meeting to deliver the
          findings and discuss in further detail.
        </p>
        <p class="lead mb-4 text-start">
          This meeting can be on a one-to-one basis or in a workshop to explore
          the findings with a group of client stakeholders. This approach will
          allow for a timeline of actions to be drafted so the client
          stakeholders can visualise how they will achieve their goals and
          objectives. Following the meeting Inspire will provide a timeline of
          actions with a proposal covering the areas where the client requires
          assistance from Inspire to achieve their desired results.
        </p>

        <p class="lead mb-0 text-coolorange">
          <strong>
            Use our knowledge and expertise to achieve the results you need!
          </strong>
        </p>
      </div>
    </div>
    <div class="rotate-180" aria-hidden="true">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          class="text-coolblue"
          d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  </Layout>
)

export default resources
